import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import '../stylesheets/App.css';

export default function Home() {
  return (
    <>
      <Typography variant="h2" className="page-title" >Accepted Insurance</Typography>
      <List>
        <ListItem className="ListItem">Hawaii Dental Service</ListItem>
        <ListItem className="ListItem">United Concordia</ListItem>
        <ListItem className="ListItem">Cigna</ListItem>
        <ListItem className="ListItem">Metropolitan Life</ListItem>
        <ListItem className="ListItem">Aetna</ListItem>
        <ListItem className="ListItem">GEHA</ListItem>
        <ListItem className="ListItem">HMSA</ListItem>
      </List>
    </>
  );
};