import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import '../stylesheets/App.css';

export default function AdvancedProcedures() {
  return (
    <>
      <Typography variant="h2" className="page-title">Advanced Procedures</Typography>
      <List>
        <ListItem className="ListItem">
          <Typography variant="h3" fontSize="20px" fontWeight="700">CROWNS</Typography>
          <p>Crowns are full coverage restorations that are used to cover a tooth that is likely to break, or is too broken down to be restored with a filling. They are most commonly done after root canal treatment, or when a large filling wears out. The larger the hole made by a cavity that has to be treated, the more likely a crown will be needed. Even after a filling is put in a large cavity, a tooth is more likely to break. Keep in mind that the jaw muscles are the strongest in the human body. Teeth are subjected to tremendous pressures. Crowns ride over the weakened tooth, providing strength and protecting the tooth against breakage. A broken or cracked tooth is a far more serious matter and much more difficult to treat. Crowns prevent this, as well as making for a nice smile.</p>
          <p>It takes two appointments to restore a tooth with a crown. In the first any decay is removed from the tooth and it is shaped to accept the crown. Then an impression is made of the tooth for use in fabricating a crown. Between the two visits the crown is made, usually of high-strength porcelain over gold alloy, all ceramic material, or gold. During this time a temporary crown is worn. In the second visit this temporary is removed. Then the permanent crown is adjusted as needed and then cemented in place.</p>
        </ListItem>
        <ListItem className="ListItem">
          <Typography variant="h3" fontSize="20px" fontWeight="700">DENTURES</Typography>
          <p>There are different types of dentures, but they share their common function. They replace teeth that have become loose or been lost due to bone loss. When bone loss around the roots of teeth is great enough to loosen them or let them fall out, it's time for dentures. Relax. No one enjoys losing their natural teeth, but you can still eat and talk regularly.</p>
          <p>The entire mouth is examined and a determination is made as to which teeth will have to be removed, and which will remain. The loose teeth are then extracted. Dentures are fitted to go over or around whatever teeth remain in the mouth, depending on the type. There is an adjustment period after dentures are placed in the mouth, and it can take some getting used to. But once accustomed to the dentures, all the normal functionality and appearance return and one just carries on as usual. Often implants can used to further stabilize the dentures.</p>
        </ListItem>
        <ListItem className="ListItem">
          <Typography variant="h3" fontSize="20px" fontWeight="700">IMPLANTS</Typography>
          <p>A dental implant is an option to replace a missing tooth. In this procedure, a small titanium shaft is surgically implanted into the bone and allowed to set. The bone grows around it forming a tight connection, which additionally slows or stops the bone loss that occurs when the root of a natural tooth is missing. Once the implant is firmly set in the mouth, the dentist then works to attach the replacement tooth onto the top of the shaft. This permanent solution has the advantages over bridge work that it does not stress the surrounding teeth for support, and, should the tooth wear out, another can simply be replaced on the shaft.</p>
          <p>Implants can also be used as support as part of an implant bridge. This is an alternative to partial dentures, and has several advantages. First, there is no adjustment period to acclimatize the patient who, once the work is done, only feels teeth, not metal supports intruding into the mouth. Second, this slows the bone loss occasioned by missing teeth. Third, there is no discomfort or difficulty in eating. And, best of all, of course, they don't have to be taken out all the time.</p>
          <p>We also offer mini dental implants. These implants are about half the diameter of traditional implants are used mainly to stabilize lower dentures. These implants can be placed in one appointment and be immediately used. The cost is 50-70% of standard dental implants. Call for a free consult.</p>
        </ListItem>
        <ListItem className="ListItem">
          <Typography variant="h3" fontSize="20px" fontWeight="700">ROOT CANAL TREATMENT</Typography>
          <p>Root canal treatment (also referred to as root canal therapy or endodontic therapy) is made necessary when a cavity is allowed, through neglect, to reach all the way to this pulp. (Regular cleanings and checkups prevent and detect problems early) Sometimes deep restorations or trauma to a tooth may cause the nerve to be damaged to the point it needs root canal therapy, also. Once this occurs the pulp becomes infected, and can even extend through the root tip and begin to eat away at the surrounding bone (this is an abscess). By the time the pulp is infected it must be treated, and cannot heal on its own. It can even weaken the entire immune system. This is dangerous, not to mention very painful. Symptoms that the pulp has become infected may include sensitivity to hot/cold or sweets, pain, swelling, pain to biting or pressure, and a bad taste in the mouth. Sometimes, however, no symptoms are apparent and the person is unaware of any problem until a checkup.</p>
          <p>A root canal is then performed to clean out the infected tooth pulp, and disinfect the canals of the tooth. The only other treatment would be to extract the tooth. Once the infection is resolved, the canal(s) are filled in to prevent any further infection. Usually a core build-up and crown is recommended for restoring a tooth that has had root canal therapy.</p>
        </ListItem>
        <ListItem className="ListItem">
          <Typography variant="h3" fontSize="20px" fontWeight="700">BRIDGES</Typography>
          <p>This is an option for filling the space created by a missing tooth. It is formed to look like the missing tooth, and it takes its place in the mouth. The sides of a bridge use the two surrounding teeth for support, hence the name. A bridge replaces the missing tooth, both functionally and cosmetically. Bridge work is as much an art as it is an exact science. The materials used may be gold alloys, porcelain bonded to metal alloy, or all ceramic material. The choice of material depends on requirements for strength, wear, and/or esthetics.</p>
          <p>It is important that a missing tooth be replaced as soon as possible for several reasons. If not treated the teeth surrounding the gap begin to shift inward, creating a whole chain reaction of bad things. Teeth use their neighbors for support, and, with one missing, they start to "fall." As this worsens the bite changes in response to the pressure. This can eventually result in problems with the entire jaw, e.g. TMJ. The surrounding teeth deteriorate and it is just a matter of time before they, too, are lost. Gum disease becomes a serious problem, with the difficulty of treatment increasing as the neglect continues.</p>
        </ListItem>
        <ListItem className="ListItem">
          <Typography variant="h3" fontSize="20px" fontWeight="700">TMJ TREATMENTS</Typography>
          <p>TMJ stands for temporal-mandibular joint. Temporal, as in temple area of skull; mandibular as in mandible, or lower jaw; joint as in it's where the head and jaw meet. Problems in this joint may be caused by a misalignment of the teeth, trauma, or excess muscle tension. Aside from the two bones that meet there, cartilage buffers them and five muscles are involved in the area. If something goes wrong a good deal of trouble can result.</p>
          <Typography variant="h4" fontSize="16px" fontWeight="700">Problems in this area can cause:</Typography>
          <List>
            <ListItem dense={true}>Headaches</ListItem>
            <ListItem dense={true}>Earaches</ListItem>
            <ListItem dense={true}>Trouble/soreness in opening and closing the mouth</ListItem>
            <ListItem dense={true}>Clicking or popping of the jaw</ListItem>
            <ListItem dense={true}>Pain in the jaw muscles</ListItem>
            <ListItem dense={true}>Soreness in the area, sometimes extending to the face</ListItem>
          </List>
          <p>Dental treatments for the condition can include replacing missing teeth, moving teeth, adjusting the bite, filling gaps between teeth, etc. There is no one solution that is right for all cases. Sometimes a plastic mouthpiece is used to prevent clenching or grinding that is contributing to the problem. If untreated and taken to extremes, surgery may be required to repair a badly damaged joint.</p>
        </ListItem>
      </List>
    </>
  );
};