import Typography from '@mui/material/Typography';

import '../stylesheets/Meet.css';
import '../stylesheets/App.css';

export default function MeetTheDoctor() {
  return (
    <>
      <Typography variant="h2" className="page-title">Meet The Doctor</Typography>
      <div className='meet__info'>
        <img className="meet__img" src={require('../images/chris.jpg')} alt="Chris Woo"></img>
        <div className='meet__text'>
          <Typography variant="p" sx={{ marginBottom: '20px' }}>Dr. Christopher Woo is a 1984 graduate of Indiana University School of Dentistry. He has operated his practice for over 31 years at the same location on Kapiolani Blvd, and is a 17 year member of the Academy of R.V. Tucker study club, as well as a 12 year member of Terry Tanaka Study Club/Hawaii. He is also an adjunct professor at the University of Missouri, Kansas City, and a mentor to the Tucker Study Club/Japan.</Typography>
          <Typography variant="p" sx={{ marginBottom: '20px' }}>Our goal with every patient is to restore function, esthetics and stability. We have 22 years of experience treating TMJ pain, teeth grinding, and pain the facial muscles and jaws. Extensive continuing education for the last 17 years enables Dr. Woo to provide the dental care you desire.</Typography>
          <Typography variant="p">Dr. Woo is a USA olympian in the sport of swimming. He has two children, and loves to play golf, swim, and cycle.</Typography>
        </div>
      </div>
    </>
  );
};
