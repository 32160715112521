import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const pages = [
  { name: 'Home', route: '/' },
  { name: 'Meet the Doctor', route: '/meet-the-doctor' },
  { name: 'Cosmetic Dentistry', route: '/cosmetic-dentistry' },
  { name: 'Advanced Procedures', route: '/advanced-procedures' },
  { name: 'Accepted Insurance', route: '/accepted-insurance' },
  { name: 'FAQ', route: '/faq' },
];

export default function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* desktop menu */}
          <Typography
            variant="h1"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              flexGrow: 1,
              color: '#fff',
              textDecoration: 'none',
              overflow: 'visible',
              fontSize: '24px'
            }}
          >
            Christopher Woo, DDS
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              <Button component={RouterLink} to={page.route} key={index} sx={{ paddingLeft: '20px', paddingRight: '10px' }}>
                <Typography sx={{ color: "#fff", fontSize: '14px' }}>{page.name}</Typography>
              </Button>
            ))}
          </Box>

          {/* mobile menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={handleCloseNavMenu}>
                  <Button component={RouterLink} to={page.route} key={index} sx={{ justifyContent: 'flex-start' }}>
                    <Typography sx={{ fontSize: '12px' }}>{page.name}</Typography>
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h1"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 700,
              color: '#fff',
              textDecoration: 'none',
              fontSize: '20px'
            }}
          >
            Christopher Woo, DDS
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
