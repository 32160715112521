import Typography from '@mui/material/Typography';

import '../stylesheets/Home.css';
import '../stylesheets/App.css';

export default function Home() {
  return (
    <>
      <Typography variant="h2" className="page-title">Aloha!</Typography>
      <div className="home__info">
        <img className="home__img" src={require('../images/swim.png')} alt="Chris Woo"></img>
        <div className="home__text">
          <Typography variant="p" sx={{ marginBottom: '20px' }}>Christopher Woo specializes in beautifying smiles, maintaining your dental health, and helping you improve your appearance. Our experienced and friendly staff takes great pride in keeping your smile beautiful. Our promise to you is that the veneers, fillings, implants, crowns, and bridges we create will look as good as or better than the teeth nature gave you. And to make sure we keep that promise, the products we recommend and use are the highest quality and incredibly durable. You can trust your smile with us.</Typography>
          <Typography variant="p" sx={{ marginBottom: '20px' }}>New patients welcome.</Typography>
          <Typography variant="h3" sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '5px' }}>Hours</Typography>
          <Typography variant="p" sx={{ marginBottom: '20px' }}>10am-4pm | Tue, Wed, Fri, Sat</Typography>
          <Typography variant="h3" sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '5px' }}>Location</Typography>
          <a style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} href="https://goo.gl/maps/L4fhbPPHU9RsqjwPA" target="_blank" rel="noreferrer">
            <Typography variant="p">1221 Kapiolani Blvd</Typography>
            <Typography variant="p">Suite 819</Typography>
            <Typography variant="p">Honolulu, HI 96814</Typography>
          </a>
          <Typography variant="h3" sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '5px' }}>Office Phone</Typography>
          <Typography variant="p" sx={{ marginBottom: '20px' }}>(808) 597-8197</Typography>
        </div>
      </div>
    </>
  );
};