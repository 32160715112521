import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import Home from '../pages/Home';
import Header from './Header';
import Footer from './Footer';
import AcceptedInsurance from '../pages/AcceptedInsurance';
import MeetTheDoctor from '../pages/MeetTheDoctor';
import CosmeticDentistry from '../pages/CosmeticDentistry';
import AdvancedProcedures from '../pages/AdvancedProcedures';
import FAQ from '../pages/FAQ';
import '../stylesheets/App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className='Body'>
          <main className='Content'>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/accepted-insurance' element={<AcceptedInsurance />} />
              <Route exact path='/meet-the-doctor' element={<MeetTheDoctor />} />
              <Route exact path='/cosmetic-dentistry' element={<CosmeticDentistry />} />
              <Route exact path='/advanced-procedures' element={<AdvancedProcedures />} />
              <Route exact path='/faq' element={<FAQ />} />
            </Routes>
            <Footer />
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;
