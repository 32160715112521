import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import '../stylesheets/App.css';

export default function CosmeticDentistry() {
  return (
    <>
      <Typography variant="h2" className="page-title">Cosmetic Dentistry</Typography>
      <p>This can be anything done to correct imperfections in the appearance of the mouth. Anyone who is unhappy with their smile can have it fixed. The upper teeth show, usually, only when smiling, while the lower teeth remain hidden. This is reversed when talking, with the upper teeth remaining hidden while the lower teeth show. The color, alignment, spacing as well as regularity of the teeth are the characteristics that give the overall appearance. Any of these can be repaired to give a stunning look to the mouth.</p>
      <p>We offer a wide variety of cosmetic options. Some of these options are whitening, smile design, recontouring of the shape of the teeth, veneers, bonding, and all ceramic/porcelain crowns.</p>
      <List>
        <ListItem className="ListItem">
        <Typography variant="h3" fontSize="20px" fontWeight="700">WHITENING - INCLUDING Zoom!</Typography>
          <p>This is the procedure of making teeth whiter, and therefore more attractive. Our office uses several methods: Zoom!, passive tray whitening, and professional strength white strips.</p>
          <p>Zoom! is a revolutionary tooth whitening procedure. It's safe effective and fast, very fast. In just over an hour, your teeth will be dramatically whiter. The convenience of Zoom! in comparison to days of using strips or wearing trays makes it the perfect choice for the busy individual. The Zoom! Whitening procedure is simple. It begins with a short preparation to isolate your lips and gums. The clinician then applies the proprietary Zoom! whitening gel that is activated by a specially designed light. Teeth typically become at least six to ten shades whiter, sometimes more. A fluoride treatment completes the procedure. You'll be amazed with the results. In most cases, teeth get even whiter the first few days after the procedure. Trays with gel are recommended afterward for maximum whitening and maintenance.</p>
          <p>The tray method involves having impressions taken from which laboratory fabricated custom vinyl trays are made. A carbamide peroxide gel is placed in these trays and the trays with gel are worn for about 3 hours, or overnight. The entire process takes 3-4 weeks of daily use. Three different strengths of gel may be used, however the higher the strength the greater the likelihood of reactions or tooth sensitivity. The tray method is recommended for badly stained teeth, such as tetracycline staining, and it may take several months of use to see significant color improvement. For maintenance of whitened teeth the trays should be used every several months.</p>
          <p>The end results whether using trays at home or doing Zoom! is the same. The cost of the tray method is about half compared to Zoom!, and there is usually less post-treatment sensitivity.</p>
        </ListItem>
        <ListItem className="ListItem">
          <Typography variant="h3" fontSize="20px" fontWeight="700">VENEERS</Typography>
          <p>Veneers are a dental procedure in which a covering is placed over the outside (visible area) of the tooth. Veneers are usually only done to the part of the teeth that are visible when talking or smiling. The procedure can be direct or indirect.</p>
          <p>The direct technique usually involves placing composite resin on the outside of the tooth using bonding. This method is usually referred to as bonding.</p>
          <p>The indirect technique usually involves two appointments because the veneers will be fabricated at a dental laboratory. At the first appointment the teeth are prepared, impressions taken, and the teeth are given a temporary covering. In two to three weeks the veneers are back from the laboratory, the temporaries are removed and the veneers are bonded to the teeth. The laboratory fabricated veneers are usually made using porcelain or pressed ceramic, and are very esthetic.</p>
          <p>The advantage of veneers versus crowns is that much less tooth material is removed, and the procedure is generally less uncomfortable. Veneers are recommended for teeth that have large fillings or little tooth structure.</p>
        </ListItem>
        <ListItem className="ListItem">
          <Typography variant="h3" fontSize="20px" fontWeight="700">INVISALIGN</Typography>
          <p>Invisalign straightens your teeth with a series of clear, virtually invisible custom-molded aligners. By using a series of clear, removable aligners, Invisalign straightens your teeth with results you'll notice sooner than you think. The course of treatment involves changing aligners approximately every two weeks, moving your teeth into straighter position step by step, until you have a more beautiful smile. And unlike braces, these clear aligners can be removed while you eat and brush your teeth as usual.</p>
        </ListItem>
      </List>
    </>
  );
};